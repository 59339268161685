import React, { useEffect, useState, useRef } from "react";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import userService from "../../services/UserService";
import CustomBackdrop from "../backdrop/CustomBackdrop";
import { useSelector, useDispatch } from "react-redux";
import CheckLogIn from "../../auth/CheckLogIn";
import { trueLogin, switchLogin } from "../../Redux/actions/LoginAction";
import SnackBar from "../snackBar/SnackBar";
import { useMediaQuery } from "react-responsive";
import CheckLoginForRegister from "../../auth/CheckLoginForRegister";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import InputLabel from '@mui/material/InputLabel';
import FormControl from "@mui/material/FormControl";
// password field
import CustomizedSteppers from "../register/stepper"
import CountryNames from "../register/CountryNames";

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';




export default function UpdateProfile(props) {

    const dispatch = useDispatch();
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 700px)" });
    const useStyles = makeStyles((theme) => ({
        root: {
            minHeight: "100vh",
        },
        image: {
            backgroundImage: "url(./wp-content/uploads/2020/12/newlogin.jpeg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
        },
        paper: {
            margin: theme.spacing(8, 4),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: isTabletOrMobile ? "0px" : "0px 100px 0px 100px",
        },
        icon: {
            display: "flex",
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        form: {
            width: "100%", // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
            backgroundColor: "#3ba1da",
        },
        back: {
            margin: theme.spacing(3, 0, 2),
        },
        buttonSignIn: {
            backgroundColor: "#3ba1da",
        },
    }));
    const classes = useStyles();

    // popup
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    
    const [msg, setmsg] = React.useState("");
    // loader
    const [loader, setLoader] = React.useState(false);
    // location screen
    const [address, setAddress] = useState({ street: "", apartment: "", neighborhood: "", city: "", region: "", country: "" })
    const [mailingAddress, setMailingAddress] = useState("")
    const [step, setStep] = useState(0)
    // 3rd step
    const [birthday, setBirthday] = useState({ day: "", month: "", year: "" })
    const [placeOfBirth, setbirthPlace] = useState("")
    const [identification, setIdendification] = useState({ number: "", type: "Identity Card" })
    const [idPic, setIdPic] = useState({ name: "", url: "" })
    const fileChooser = useRef(null);
    // render screen
    const [render, setRender] = useState(false)
    const [currentUser, setUser] = useState({})
    let user = localStorage.getItem("user")

    useEffect((e) => {
        let user = localStorage.getItem("user");
        if (user) {
            user = JSON.parse(user)
            setUser(user)
            if (!user?.address?.street || !user?.address?.neighborhood || !user?.address?.city || !user?.address?.region || !user?.address?.country) {
                setStep(1)
                setRender(true)
            }
            else if (!user?.birthday?.month || !user?.placeOfBirth || !user?.identification?.number || !user?.idPic?.name) {
                setStep(2)
                setRender(true)
            }
            else
                props.history.push("/userProfile")

        }
        else
            props.history.push("/")
    }, [])

    const setCountry = (country) => {
        setUser({ ...currentUser, address: { ...currentUser?.address, country } });
    }

    const saveLocationData = (e) => {
        e.preventDefault()


        if (!currentUser.address.street || currentUser.address.street.length < 3) {
            setmsg("Street Address should be at least 3 characters");
            setOpen(true);
        }
        else if (!currentUser.address.neighborhood || currentUser.address.neighborhood.length < 3) {
            setmsg("Neighborhood should be at least 3 characters");
            setOpen(true);
        }
        else if (!currentUser.address.city || currentUser.address.city.length < 3) {
            setmsg("City should be at least 3 characters");
            setOpen(true);
        }
        else if (!currentUser.address.region || currentUser.address.region.length < 3) {
            setmsg("Region should be at least 3 characters");
            setOpen(true);
        }
        else if (!currentUser.address.country || currentUser.address.country.length < 3) {
            setmsg("Country should be at least 3 characters");
            setOpen(true);
        }
        // else if (!currentUser.mailingAddress || currentUser.mailingAddress.length < 3) {
        //     setmsg("Mailing Address should be at least 3 characters");
        //     setOpen(true);
        // }
        else {
            setLoader(true);
            userService
                .updateUser({
                    ...currentUser
                })
                .then(function (res) {
                    if (res.success) {
                        let updatedUser = JSON.stringify(res.user)
                        setUser(res.user)
                        localStorage.setItem("user", updatedUser);
                        setStep(2)
                    }
                    else {
                        setmsg("Something went wrong");
                        setOpen(true);
                    }
                    setLoader(false);
                })
                .catch(function (error) {
                    setLoader(false);
                    setmsg("Something went wrong");
                    console.log(error);
                    setOpen(true);
                });
        }
    };

    const savePersonalInfo = (e) => {
        e.preventDefault();
        if (!birthday.month || !birthday.day || !birthday.year) {
            setmsg("Full Date of birth is required");
            setOpen(true);
        }
        else if (!placeOfBirth || placeOfBirth.length < 3) {
            setmsg("place of birth should be at least 3 characters");
            setOpen(true);
        }
        else if (!identification.number || identification.number.length < 3) {
            setmsg(identification.type + " number should be at least 3 characters");
            setOpen(true);
        }
        else if (!idPic.name) {
            setmsg("Picture of ID is required");
            setOpen(true);
        }
        else {
            setLoader(true);
            userService.updateUser({
                ...currentUser, birthday, placeOfBirth, idPic, identification, isSignupComplete: true
            })
                .then(function (res) {
                    if (res.success) {
                        userService.sendProfileCompleteMessage(res.user._id)
                        console.log("here==>");
                        console.log(res.user);
                        let updatedUser = JSON.stringify(res.user)
                        localStorage.setItem("user", updatedUser);
                        setUser(res.user)
                        props.history.push("/userProfile")
                    }
                    else {
                        setmsg("Something went wrong");
                        setOpen(true);
                    }
                    setLoader(false);
                })
                .catch(function (error) {
                    setLoader(false);
                    setmsg("Something went wrong");
                    console.log(error);
                    setOpen(true);
                });
        }
    }

    const imageUpload = (e) => {
        e.preventDefault();
        setLoading(true)
        const image = e.target.files[0];
        const imageForm = new FormData();
        imageForm.append("image", image);
        // const data = new URLSearchParams(imageForm)
        fetch(process.env.REACT_APP_host + "/image", {
            method: "POST",
            body: imageForm,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    setIdPic({ name: e.target.files[0]?.name, url: data.data.url })
                }
        setLoading(false)

            }).catch((error) => {
                setLoading(false)
                console.error(error);
            });


    }

    return (
        <>
            {render &&
                <CheckLoginForRegister key={step}>
                    <CustomBackdrop open={loading} setOpen={setLoading} />
                    <Grid container component="main" className={classes.root}>
                        <CssBaseline />

                        <Grid item xs={false} sm={4} md={5} className={classes.image} />
                        <Grid item xs={12} sm={8} md={7} component={Paper} square>
                            <div style={{ display: "flex", marginTop: "20px" }}>
                                <Grid md={1}>
                                </Grid>
                                <Grid md={10}>
                                    <CustomizedSteppers step={step} />
                                </Grid>
                                <Grid md={1}>
                                    <div className={classes.icon}>
                                        <CloseIcon
                                            onClick={() => {
                                                userService.logout();
                                                dispatch(switchLogin());
                                                localStorage.removeItem("user");
                                                props.history.push("/")
                                            }}
                                            style={{ cursor: "pointer", float: "right", fontSize: "40px", margin: "0px", color: "rgba(24, 110, 184, 0.5)" }}
                                        />
                                    </div>
                                </Grid>
                            </div>
                            <div className={classes.paper}>

                                <a
                                    // onClick={() => { props.history.push("/"); }} 
                                    style={{ cursor: "pointer" }} >
                                    <img
                                        src="./wp-content/uploads/2020/11/cropped-MetriMeo-Blue-300x78.png"
                                        alt=""
                                        height="50px"
                                    />
                                </a>
                                <br />
                                <div style={{ width: "100%" }}>
                                    <hr />
                                </div>
                                <div
                                    style={{
                                        fontFamily: "'Montserrat',sans-serif",
                                        fontSize: "28px",
                                        textAlign: "center",
                                        fontWeight: "800",
                                    }}
                                >
                                    Welcome to a world of Trust and Financial progress
                                </div>
                                <br />
                                <SnackBar open={open} setOpen={setOpen} msg={msg} />
                                {step == 1 &&
                                    <form className={classes.form} onSubmit={saveLocationData}>
                                        <div
                                            style={{
                                                fontFamily: "'Open Sans',sans-serif",
                                                fontSize: "17px",
                                                fontWeight: "600",
                                                // textAlign: "center",
                                            }}
                                        >
                                            Physical Address *
                                        </div>
                                        <TextField
                                            value={currentUser?.address?.street}
                                            onChange={(e) => {
                                                setUser({ ...currentUser, address: { ...currentUser?.address, street: e.target.value } });
                                            }}
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            label="Street Address"
                                            name="streetAddress"
                                            autoFocus
                                        />
                                        {/* apartment */}
                                        <TextField
                                            value={currentUser?.address?.apartment}
                                            onChange={(e) => {
                                                setUser({ ...currentUser, address: { ...currentUser?.address, apartment: e.target.value } });
                                            }}
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label="Apartment Number"
                                            name="Apartment"
                                        />
                                        {/* neighborhood */}
                                        <TextField
                                            value={currentUser?.address?.neighborhood}
                                            onChange={(e) => {
                                                setUser({ ...currentUser, address: { ...currentUser?.address, neighborhood: e.target.value } });
                                            }}
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            label="Neighborhood"
                                            name="neighborhood"
                                        />
                                        {/* city */}
                                        <TextField
                                            value={currentUser?.address?.city}
                                            onChange={(e) => {
                                                setUser({ ...currentUser, address: { ...currentUser?.address, city: e.target.value } });
                                            }}
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            label="City"
                                            name="city"
                                        />
                                        {/* region */}
                                        <TextField
                                            value={currentUser?.address?.region}
                                            onChange={(e) => {
                                                setUser({ ...currentUser, address: { ...currentUser?.address, region: e.target.value } });
                                            }}
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            label="Region"
                                            name="region"
                                        />
                                        {/* country */}
                                        <CountryNames country={currentUser?.address?.country} setCountry={setCountry} />

                                        <div
                                            style={{
                                                fontFamily: "'Open Sans',sans-serif",
                                                fontSize: "17px",
                                                fontWeight: "600",
                                                // textAlign: "center",
                                            }}
                                        >
                                            Mailing Address 
                                        </div>
                                        <TextField
                                            value={currentUser?.mailingAddress}
                                            onChange={(e) => {
                                                setUser({ ...currentUser, mailingAddress: e.target.value });
                                            }}
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label="P.O BOX or Others "
                                            name="mailingAddress"
                                        />
                                        <Button
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                            onClick={(e) => saveLocationData(e)}

                                        >
                                            Next
                                        </Button>
                                    </form>
                                }
                                {step == 2 &&
                                    <form className={classes.form} onSubmit={savePersonalInfo}>
                                        <div
                                            style={{
                                                fontFamily: "'Open Sans',sans-serif",
                                                fontSize: "17px",
                                                fontWeight: "600",
                                                // textAlign: "center",
                                            }}
                                        >
                                            Date Of Birth *
                                        </div>
                                        <div style={{ marginTop: "10px", display:"flex", justifyContent:"space-between", flexDirection: isTabletOrMobile ? "column" : "row" }}>
                                            <FormControl style={{ minWidth: 180, marginTop:"10px" }}>
                                                <InputLabel id="demo-simple-select-helper-label">Month</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={birthday.month}
                                                    label="Month"
                                                    onChange={(e) => setBirthday({ ...birthday, month: e.target.value })}
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value={"Jan"}>Jan</MenuItem>
                                                    <MenuItem value={"Feb"}>Feb</MenuItem>
                                                    <MenuItem value={"Mar"}>Mar</MenuItem>
                                                    <MenuItem value={"Apr"}>Apr</MenuItem>
                                                    <MenuItem value={"May"}>May</MenuItem>
                                                    <MenuItem value={"Jun"}>Jun</MenuItem>
                                                    <MenuItem value={"Jul"}>Jul</MenuItem>
                                                    <MenuItem value={"Aug"}>Aug</MenuItem>
                                                    <MenuItem value={"Sep"}>Sep</MenuItem>
                                                    <MenuItem value={"Oct"}>Oct</MenuItem>
                                                    <MenuItem value={"Nov"}>Nov</MenuItem>
                                                    <MenuItem value={"Dec"}>Dec</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl style={{ minWidth: 180, marginTop:"10px" }}>
                                                <InputLabel  id="demo-simple-select-helper-label">Day</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={birthday.day}
                                                    label="day"
                                                    onChange={(e) => setBirthday({ ...birthday, day: e.target.value })}
                                                >
                                                    <MenuItem value="">
                                                    </MenuItem>
                                                    {Array.from(Array(31).keys()).map(number =>
                                                        <MenuItem value={number + 1}>{number + 1}</MenuItem>
                                                    )}

                                                </Select>
                                            </FormControl>
                                            <FormControl style={{ minWidth: 180, marginTop:"10px" }}>
                                                <InputLabel id="demo-simple-select-helper-label">Year</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={birthday.year}
                                                    label="year"
                                                    onChange={(e) => setBirthday({ ...birthday, year: e.target.value })}
                                                >
                                                    <MenuItem value="">
                                                    </MenuItem>
                                                    {Array.from(Array(78).keys()).map(number =>
                                                        <MenuItem value={number + 1940}>{number + 1940}</MenuItem>
                                                    )}

                                                </Select>
                                            </FormControl>
                                        </div>

                                        <TextField
                                            value={placeOfBirth}
                                            onChange={(e) => {
                                                setbirthPlace(e.target.value);
                                            }}
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            label="Place Of Birth"
                                            name="palceOFBirth"
                                        />
                                        <div
                                            style={{
                                                fontFamily: "'Open Sans',sans-serif",
                                                fontSize: "17px",
                                                fontWeight: "600",
                                                marginTop: "10px"
                                                // textAlign: "center",
                                            }}
                                        >
                                            Identification Document (ID)*
                                        </div>
                                        <FormControl component="fieldset">

                                            <RadioGroup value={identification.type} onChange={e => setIdendification({ ...identification, type: e.target.value })} row aria-label="idCard" name="row-radio-buttons-group">
                                                <FormControlLabel value="Identity Card" control={<Radio />} label="Identity Card" />
                                                <FormControlLabel value="Passport" control={<Radio />} label="Passport" />
                                                <FormControlLabel value="Birth Certificate" control={<Radio />} label="Birth Certificate" />
                                            </RadioGroup>
                                        </FormControl>
                                        <TextField
                                            value={identification.number}
                                            onChange={(e) => {
                                                setIdendification({ ...identification, number: e.target.value });
                                            }}
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            label={`${identification.type} number `}
                                            name="idn"
                                        />

                                        <div
                                            style={{
                                                fontFamily: "'Open Sans',sans-serif",
                                                fontSize: "17px",
                                                fontWeight: "600",
                                                marginTop: "10px"
                                                // textAlign: "center",
                                            }}
                                        >
                                            Attach picture of ID *
                                        </div>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={9} style={{ paddingRight: "13px" }}>
                                                <TextField
                                                    value={idPic.name}
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    onClick={e => fileChooser.click}
                                                    disabled
                                                    name="filename"
                                                    style={{ marginRight: "10px" }}
                                                />
                                            </Grid>
                                            <Grid xs={12} sm={12} md={3}>
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    fullWidth
                                                    ref={fileChooser}
                                                    style={{ borderRadius: "10px", marginTop: "16px", height: "50px", paddingLeft:"0",paddingRight:"0" }}
                                                >

                                                    Choose file ...
                                                    <input
                                                        onChange={imageUpload}
                                                        type="file"
                                                        hidden
                                                        accept="image/*"
                                                    />
                                                </Button>
                                            </Grid>

                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} style={{display:"flex",placeContent:"space-evenly"}}>
                                            <Grid xs={12} sm={12} md={6}>

                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    style={{width:"98%"}}
                                                    className={classes.back}
                                                    onClick={(e) => setStep(1)}
                                                >
                                                    BACK
                                                </Button>
                                            </Grid>
                                            <Grid xs={12} sm={12} md={6}>

                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    style={{width:"98%"}}
                                                    color="primary"
                                                    className={classes.submit}
                                                    onClick={(e) => savePersonalInfo(e)}

                                                >
                                                    Finish
                                                </Button>
                                            </Grid>
                                        </Grid>

                                    </form>
                                }
                            </div>
                        </Grid>
                    </Grid>
                    <CustomBackdrop open={loader} />
                </CheckLoginForRegister>

            }
        </>
    );
};




