import React, { useEffect, useState, useRef } from "react";
import Button from "@material-ui/core/Button";
import { Container } from "reactstrap";
import { Grid } from "@material-ui/core";
import CountryNames from "../register/CountryNames";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import InputLabel from '@mui/material/InputLabel';
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SnackBar from "../snackBar/SnackBar";
import CustomBackdrop from "../backdrop/CustomBackdrop";
import userService from "../../services/UserService";
import Stack from '@mui/material/Stack';
import ButtonM from '@mui/material/Button';


export default function MyLiabilities(props) {
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 700px)" });
    const useStyles = makeStyles((theme) => ({
        root: {
            minHeight: "100vh",
        },
        image: {
            backgroundImage: "url(./wp-content/uploads/2020/12/newlogin.jpeg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
        },
        paper: {
            margin: theme.spacing(8, 4),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: isTabletOrMobile ? "0px" : "0px 100px 0px 100px",
        },
        icon: {
            display: "flex",
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        form: {
            width: "100%", // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
            backgroundColor: "#3ba1da",
        },
        buttonSignIn: {
            backgroundColor: "#3ba1da",
        },
        InputLabel: {
            fontSize: isTabletOrMobile ? "13px" : "16px"
        }
    }));
    const classes = useStyles();
    const categoryDB = ["Notebook", "Desktop PC", "Monitor"];
    const [render, setRender] = useState(false)
    const [loader, setLoader] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [msg, setmsg] = React.useState("");
    const [currentUser, setUser] = useState({})

    const [oldestActiveLoan, setOldestActiveLoan] = useState({ year: "2015", month: "Jan" })
    const [defaultedOnAny7YearLoan, setDefaultedOnAny7YearLoan] = useState({ ans: "No", howMany: "" })
    const [bankruptcyLast7Years, setbankruptcyLast7Years] = useState({ ans: "No", howLong: "" })
    const [activeLoans, setActiveLoan] = useState([])
    const [numberOFActiveLoans, setNumberOFActiveLoans] = useState("");
    const [late30to59Days, setLate30to59Days] = useState("");
    const [late60to89Days, setLate60to89Days] = useState("");
    const [late90PlusDays, setLate90PlusDays] = useState("");

    const activeLoanQuestions = { lenderName: "", lenderType: "", loanGrantedOn: "", relationshipToTheLoan: "", purposeOfTheLoan: "", paymentFrequency: "", paymentFrequencyPrice: { type: "FCFA", amount: "" }, currentBalance: { type: "FCFA", amount: "" } }
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"))
        console.log(user);
        if (user?.numberOFActiveLoans)
            setNumberOFActiveLoans(user.numberOFActiveLoans)
        if (user?.oldestActiveLoan)
            setOldestActiveLoan(user.oldestActiveLoan)
        if (user?.defaultedOnAny7YearLoan)
            setDefaultedOnAny7YearLoan(user.defaultedOnAny7YearLoan)
        if (user?.bankruptcyLast7Years)
            setbankruptcyLast7Years(user.bankruptcyLast7Years)
        if (user?.activeLoans)
            setActiveLoan(user.activeLoans)
        if(user?.late30to59Days || user?.late30to59Days==0)
            setLate30to59Days(user.late30to59Days)
        if(user?.late60to89Days || user?.late60to89Days==0)
            setLate60to89Days(user.late60to89Days)
        if(user?.late90PlusDays || user?.late90PlusDays==0)
            setLate90PlusDays(user.late90PlusDays)
        setUser(user)
        setRender(true)
    }, [])

    const saveLiabilities = (e) => {
        e.preventDefault();
        console.log({ numberOFActiveLoans, oldestActiveLoan, defaultedOnAny7YearLoan, bankruptcyLast7Years, activeLoans,late30to59Days,late60to89Days,late90PlusDays });
        let body = { numberOFActiveLoans, "scoreoTabEnabled": true, oldestActiveLoan: {}, defaultedOnAny7YearLoan: {}, bankruptcyLast7Years: {}, activeLoans: {} }
        let sendRequest = true;
        body = { ...body, defaultedOnAny7YearLoan, bankruptcyLast7Years,late30to59Days,late60to89Days,late90PlusDays }
        if (defaultedOnAny7YearLoan?.ans == "Yes" && (defaultedOnAny7YearLoan?.howMany == "" || !defaultedOnAny7YearLoan?.howMany)) {
            setmsg(`Please let as know how many loan you are defaulted`);
            setOpen(true);
            sendRequest = false;
        }
        else if (bankruptcyLast7Years?.ans == "Yes" && (bankruptcyLast7Years?.howMany == "" || !bankruptcyLast7Years?.howMany)) {
            setmsg(`Please let as know how long you ago did you bankruptcy`);
            setOpen(true);
            sendRequest = false;
        }
        else if (late30to59Days==="") {
            setmsg(` How many times have you been late from 30 to 59 days is required`); setOpen(true);
            sendRequest = false;
        }
        else if (late60to89Days==="") {
            setmsg(` How many times have you been late from 60 to 89 days is required`); setOpen(true);
            sendRequest = false;

        }
        else if (late90PlusDays === "") {
            setmsg(` How many times have you been late 90+ days is required`); setOpen(true);
            sendRequest = false;

        }

        else if (parseInt(numberOFActiveLoans) > 0) {
            sendRequest = false;
            setmsg("")
            console.log(activeLoans.length, parseInt(numberOFActiveLoans));
            if (activeLoans.length < parseInt(numberOFActiveLoans)) {
                if (parseInt(numberOFActiveLoans) == 1) {
                    setmsg(`Please add loan`);
                    setOpen(true);
                }
                else {
                    setmsg(`You input ${numberOFActiveLoans} active loan please add ${parseInt(numberOFActiveLoans) - activeLoans.length} more loan`);
                    setOpen(true);
                }
            }
            else if (!oldestActiveLoan.year || !oldestActiveLoan.month) {
                setmsg(`Oldest active loan is required`);
                setOpen(true);
            }
            else if (activeLoans.length) {

                for (let index = 0; index < activeLoans.length; index++) {
                    let loan = activeLoans[index];
                    if (loan.lenderName?.length < 3) {
                        setmsg(`Loan ${index + 1}: Lender name should be at least 3 characters`);
                        setOpen(true);
                        break;
                    }
                    else if (!loan.lenderType) {
                        setmsg(`Loan ${index + 1}: Lender type should is required`); setOpen(true);
                        break;
                    }
                    else if (!loan.loanGrantedOn) {
                        setmsg(`Loan ${index + 1}: loan granted date is required`); setOpen(true);
                        break;
                    }
                    else if (!loan.relationshipToTheLoan) {
                        setmsg(`Loan ${index + 1}: relationship to the loan is required`); setOpen(true);
                        break;
                    }
                    else if (!loan.purposeOfTheLoan) {
                        setmsg(`Loan ${index + 1}: Purpose to the loan is required`); setOpen(true);
                        break;
                    }
                    else if (!loan.paymentFrequency) {
                        setmsg(`Loan ${index + 1}: Payment frequency to the loan is required`); setOpen(true);
                        break;
                    }
                    else if (!loan.paymentFrequencyPrice.amount) {
                        setmsg(`Loan ${index + 1}: Payment amount is required`); setOpen(true);
                        break;
                    }
                    else if (!loan.currentBalance.amount) {
                        setmsg(`Loan ${index + 1}: current balance of the loan is required`); setOpen(true);
                        break;
                    }
                    else if ((index + 1) == parseInt(numberOFActiveLoans)) {
                        sendRequest = true;
                        body = { ...body, numberOFActiveLoans, oldestActiveLoan, defaultedOnAny7YearLoan, bankruptcyLast7Years, activeLoans }
                    }
                }

            }


        }
        else if (numberOFActiveLoans == "" && numberOFActiveLoans != 0) {
            sendRequest = false;
            console.log(numberOFActiveLoans);
            setmsg(`Please let as know active know count`);
            setOpen(true);
        }
        if (sendRequest) {
            setLoader(true);
            console.log(body);
            userService.updateUser({ _id: currentUser._id, ...body }).then(function (res) {
                if (res.success) {
                    let updatedUser = JSON.stringify(res.user)
                    localStorage.setItem("user", updatedUser);
                    console.log(res.user);
                    setUser(res.user)
                    props.setSmsg("Liabilities Information Saved");
                    props.setOpenS(true)
                    props.setParentTab(0)
                }
                else {
                    setmsg("Something went wrong");
                    setOpen(true);
                }
                setLoader(false);
            }).catch(function (error) {
                setLoader(false);
                setmsg("Something went wrong");
                setOpen(true);
                console.log(error);
            });
        }

    }


    const add = (e, index) => {
        e.preventDefault();
        activeLoans[index] = { ...activeLoans[index], [e.target.name]: e.target.value };
        setActiveLoan([...activeLoans])
    }

    const addPrice = (e, index, type) => {
        e.preventDefault();
        console.log(e.target.name);
        console.log(e.target.value);
        if (e.target.value < 0)
            e.target.value = 0
        if (type == "paymentFrequencyPrice")
            if (e.target.name == "type")
                activeLoans[index] = { ...activeLoans[index], "paymentFrequencyPrice": { ...activeLoans[index].paymentFrequencyPrice, [e.target.name]: e.target.value } };
            else
                activeLoans[index] = { ...activeLoans[index], "paymentFrequencyPrice": { ...activeLoans[index].paymentFrequencyPrice, [e.target.name]: parseFloat(e.target.value) } };
        else if (type == "currentBalance")
            if (e.target.name == "type")
                activeLoans[index] = { ...activeLoans[index], "currentBalance": { ...activeLoans[index].currentBalance, [e.target.name]: e.target.value } };
            else
                activeLoans[index] = { ...activeLoans[index], "currentBalance": { ...activeLoans[index].currentBalance, [e.target.name]: parseFloat(e.target.value) } };
        console.log(activeLoans[index]);
        setActiveLoan([...activeLoans])
    }

    const removeLoan = (e) => {
        e.preventDefault();
        if (activeLoans.length > 0) {
            const loan = activeLoans;
            loan.pop()
            setActiveLoan([...loan])
            console.log(loan);
        }
    }
    const addLoan = (e) => {
        e.preventDefault();
        console.log(activeLoans.length, parseInt(numberOFActiveLoans));
        if (activeLoans.length < parseInt(numberOFActiveLoans)) {
            const newLoan = activeLoans;
            newLoan.push(activeLoanQuestions)
            setActiveLoan([...newLoan])
        }
        else {
            setmsg("Total Number of loans are complete");
            setOpen(true);
        }


    }

    return (
        <> {render &&
            <div style={{ marginBottom: "50px" }}>
                <SnackBar open={open} setOpen={setOpen} msg={msg} />
                <form className={classes.form} style={{ display: "flex" }} onSubmit={saveLiabilities}>
                    <Grid container>

                        <Grid item xs={12} sm={12} md={12} >
                            <div
                                style={{
                                    fontFamily: "'Open Sans',sans-serif",
                                    fontSize: "17px",
                                    fontWeight: "600",
                                    marginTop: "10px",
                                }}
                            >
                                Have you defaulted on any loan over the past 7 years?
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                            <FormControl component="fieldset" style={{ marginTop: "15px" }}>
                                <RadioGroup
                                    value={defaultedOnAny7YearLoan?.ans}
                                    onChange={e => setDefaultedOnAny7YearLoan({ ans: e.target.value, howLong: "" })}
                                    row
                                    aria-label="idCard"
                                    name="row-radio-buttons-group"
                                >
                                    <FormControlLabel
                                        value="No"
                                        control={<Radio />}
                                        label="No"
                                    />
                                    <FormControlLabel
                                        value="Yes"
                                        control={<Radio />}
                                        label="Yes"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {defaultedOnAny7YearLoan?.ans == "Yes" &&
                            <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                                <TextField
                                    value={defaultedOnAny7YearLoan?.howMany ? String(parseInt(defaultedOnAny7YearLoan?.howMany)).toString() : defaultedOnAny7YearLoan?.howMany}
                                    onChange={e => {
                                        setDefaultedOnAny7YearLoan({ ...defaultedOnAny7YearLoan, howMany: e.target.value })
                                    }}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    type="number"
                                    label={"How Many Loans?"}
                                    name="manyLoans"
                                />
                            </Grid>
                        }

                        <Grid item xs={12} sm={12} md={12} style={{ marginRight: "15px" }}>
                            <div
                                style={{
                                    fontFamily: "'Open Sans',sans-serif",
                                    fontSize: "17px",
                                    fontWeight: "600",
                                    marginTop: "10px",
                                }}
                            >
                                Have you filed for bankruptcy within the last 7 years?
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                            <FormControl component="fieldset" style={{ marginTop: "15px" }}>
                                <RadioGroup
                                    value={bankruptcyLast7Years?.ans}
                                    onChange={e => setbankruptcyLast7Years({ ans: e.target.value, howLong: "" })}
                                    row
                                    aria-label="idCard"
                                    name="row-radio-buttons-group"
                                >
                                    <FormControlLabel
                                        value="No"
                                        control={<Radio />}
                                        label="No"
                                    />
                                    <FormControlLabel
                                        value="Yes"
                                        control={<Radio />}
                                        label="Yes"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {bankruptcyLast7Years?.ans == "Yes" &&
                            <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                                <TextField
                                    value={String(parseInt(bankruptcyLast7Years?.howMany)).toString()}
                                    onChange={e => {
                                        setbankruptcyLast7Years({ ...bankruptcyLast7Years, howMany: e.target.value })
                                    }}

                                    variant="outlined"
                                    margin="normal"
                                    type="number"
                                    required
                                    fullWidth
                                    label={"How Long Ago (in months) Did It Occur?"}
                                    name="manyLong"
                                />
                            </Grid>
                        }
                        <Grid xs={12} sm={12} md={12}></Grid>
                        <Grid key={"late30to59Days" } item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                            <InputLabel style={{ marginTop: "10px", marginBottom: "0" }} id="relationshipToTheLoan">
                                How many times have you been late from {isTabletOrMobile && <br />}30 to 59 days?
                            </InputLabel>

                            <TextField
                                style={{ marginTop: "13px" }}
                                value={late30to59Days}
                                variant="outlined"
                                margin="normal"
                                type="number"
                                fullWidth
                                label="Number Of Times"
                                onChange={e => {
                                    if (e.target.value > 99) {
                                        setmsg(`Maximum 99 is allowed`);
                                        setOpen(true)
                                    }
                                    else if (e.target.value < 0) {
                                        setmsg(`Minimum 0 is allowed`);
                                        setOpen(true)
                                    }
                                    else {
                                        e.target.value = parseInt(e.target.value)
                                        setLate30to59Days(e.target.value)
                                    }
                                }}
                                name="late30to59Days"
                            />
                        </Grid>

                        <Grid key={"late60to89Days" } item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                            <InputLabel style={{ marginTop: "10px", marginBottom: "0" }} id="relationshipToTheLoan">
                                How many times have you been late from {isTabletOrMobile && <br />}60 to 89 days?
                            </InputLabel>

                            <TextField
                                style={{ marginTop: "13px" }}
                                value={late60to89Days}
                                variant="outlined"
                                margin="normal"
                                type="number"
                                fullWidth
                                label="Number Of Times"
                                onChange={e => {
                                    if (e.target.value > 99) {
                                        setmsg(`Maximum 99 is allowed`);
                                        setOpen(true)
                                    }
                                    else if (e.target.value < 0) {
                                        setmsg(`Minimum 0 is allowed`);
                                        setOpen(true)
                                    }
                                    else {
                                        e.target.value = parseInt(e.target.value)
                                        setLate60to89Days(e.target.value)
                                    }
                                }}
                                name="late60to89Days"
                            />
                        </Grid>

                        <Grid key={"late90PlusDays" } item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                            <InputLabel style={{ marginTop: "10px", marginBottom: "0" }} id="relationshipToTheLoan">
                                How many times have you been late 90+ days?
                            </InputLabel>

                            <TextField
                                style={{ marginTop: "13px" }}
                                value={late90PlusDays}
                                variant="outlined"
                                margin="normal"
                                type="number"
                                fullWidth
                                label="Number Of Times"
                                onChange={e => {
                                    if (e.target.value > 99) {
                                        setmsg(`Maximum 99 is allowed`);
                                        setOpen(true)
                                    }
                                    else if (e.target.value < 0) {
                                        setmsg(`Minimum 0 is allowed`);
                                        setOpen(true)
                                    }
                                    else {
                                        e.target.value = parseInt(e.target.value)
                                        setLate90PlusDays(e.target.value)
                                    }
                                }}
                                name="late90PlusDays"
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} >
                            <div
                                style={{
                                    fontFamily: "'Open Sans',sans-serif",
                                    fontSize: "17px",
                                    fontWeight: "600",
                                    marginTop: "10px",
                                }}
                            >
                                How many active loans do you have?
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} >
                            <TextField
                                value={String(numberOFActiveLoans).toString()}
                                onChange={e => {
                                    setNumberOFActiveLoans(parseInt(e.target.value))
                                    if (e.target.value < 0)
                                        setNumberOFActiveLoans(0)
                                }}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                type="number"
                                label={""}
                                name="howmanyActive"
                            />
                        </Grid>

                        {numberOFActiveLoans != "" && numberOFActiveLoans > 0 && <>
                            <Grid item xs={12} sm={12} md={12}>
                                <div
                                    style={{
                                        fontFamily: "'Open Sans',sans-serif",
                                        fontSize: "17px",
                                        fontWeight: "600",
                                        marginTop: "10px",
                                    }}
                                >
                                    How long ago did you get your oldest active loan?
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} >
                                <Grid item xs={12} sm={12} md={12} >
                                    <div style={{ marginTop: "15px" }}>
                                        <FormControl style={{ minWidth: 145, marginRight: "15px" }}>
                                            {/* {!birthday.month &&  */}
                                            <InputLabel
                                                id="demo-simple-select-helper-label"
                                            >
                                                Month
                                            </InputLabel>
                                            {/* } */}
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                label="Month"
                                                value={oldestActiveLoan?.month || ""}
                                                onChange={(e) => setOldestActiveLoan({ ...oldestActiveLoan, month: e.target.value })}>
                                                <MenuItem value=""><em>None</em></MenuItem>
                                                <MenuItem key={"Jan"} value={"Jan"}>Jan</MenuItem>
                                                <MenuItem key={"Jan"} value={"Jan"}>Jan</MenuItem>
                                                <MenuItem key={"Feb"} value={"Feb"}>Feb</MenuItem>
                                                <MenuItem key={"Mar"} value={"Mar"}>Mar</MenuItem>
                                                <MenuItem key={"Apr"} value={"Apr"}>Apr</MenuItem>
                                                <MenuItem key={"May"} value={"May"}>May</MenuItem>
                                                <MenuItem key={"Jun"} value={"Jun"}>Jun</MenuItem>
                                                <MenuItem key={"Jul"} value={"Jul"}>Jul</MenuItem>
                                                <MenuItem key={"Aug"} value={"Aug"}>Aug</MenuItem>
                                                <MenuItem key={"Sep"} value={"Sep"}>Sep</MenuItem>
                                                <MenuItem key={"Oct"} value={"Oct"}>Oct</MenuItem>
                                                <MenuItem key={"Nov"} value={"Nov"}>Nov</MenuItem>
                                                <MenuItem key={"Dec"} value={"Dec"}>Dec</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl style={{ minWidth: 140 }}>
                                            {/* {!birthday.year &&  */}
                                            <InputLabel
                                                id="demo-simple-select-helper-label"
                                            >
                                                Year
                                            </InputLabel>
                                            {/* } */}
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                label="year"
                                                value={oldestActiveLoan?.year}
                                                onChange={(e) => setOldestActiveLoan({ ...oldestActiveLoan, year: e.target.value })}>
                                                <MenuItem value=""></MenuItem>
                                                {Array.from(Array(78).keys()).map((number) => (
                                                    <MenuItem key={number + 1} value={number + 1944}>{number + 1944}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                    </div>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12} md={10} >
                                <hr />
                            </Grid>
                            {activeLoans && activeLoans.map((loan, index) => {
                                return (
                                    <>
                                        {index != 0 && <Grid item xs={12} sm={12} md={10} >
                                            <hr />
                                        </Grid>}
                                        <Grid key={"heading" + index} item xs={12} sm={12} md={12} >
                                            <div
                                                style={{
                                                    fontFamily: "'Open Sans',sans-serif",
                                                    fontSize: "17px",
                                                    fontWeight: "600",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                Active loan {index + 1}
                                            </div>
                                        </Grid>
                                        <br />
                                        <Grid key={"lenderName" + index} item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>

                                            <InputLabel style={{ marginTop: "10px", marginBottom: "0" }} id="relationshipToTheLoan">
                                                What is the lender’s name?
                                            </InputLabel>

                                            <TextField
                                                style={{ marginTop: "13px" }}
                                                value={loan.lenderName}
                                                onChange={e => add(e, index)}
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                label="Name"
                                                name="lenderName"
                                            />
                                        </Grid>

                                        <Grid key={"lenderType" + index} item xs={12} sm={12} md={5} style={{ marginTop: "15px", marginRight: "15px" }}>
                                            <InputLabel
                                                id="lenderacompany"
                                            >
                                                Is the lender a Bank, an organization or a {isTabletOrMobile && <br />}person?
                                            </InputLabel>
                                            <FormControl fullWidth >
                                                <Select
                                                    labelId="lenderacompany"
                                                    id="lenderacompanyF"
                                                    label="lenderType"
                                                    name="lenderType"
                                                    onChange={e => add(e, index)}
                                                    value={loan.lenderType}
                                                >
                                                    <MenuItem key={"bank"} value={"bank"}>Bank</MenuItem>
                                                    <MenuItem key={"organization"} value={"organization"}>Organization</MenuItem>
                                                    <MenuItem key={"person"} value={"person"}>Person</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid key={"loanGrantedOn" + index} item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                                            <InputLabel style={{ marginTop: "10px", marginBottom: "0" }} >
                                                When was the loan granted?
                                            </InputLabel>
                                            <input
                                                min="1900-01-01"
                                                className="date"
                                                style={{ marginTop: "13px" }}
                                                value={loan.loanGrantedOn}
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                type="date"
                                                name="loanGrantedOn"
                                                onKeyDown={(event) => {
                                                    event.preventDefault();
                                                }}
                                                onChange={e => {
                                                    if (new Date().getTime() > new Date(e.target.value).getTime())
                                                        add(e, index)
                                                    else {
                                                        setmsg("Loan granted date cannot be a future date");
                                                        setOpen(true);
                                                    }
                                                }}

                                            />
                                        </Grid>

                                        <Grid key={"relationshipToTheLoan" + index} item xs={12} sm={12} md={5} style={{ marginTop: "15px", marginRight: "15px" }}>
                                            <InputLabel

                                            >
                                                What is your relationship to the loan?
                                            </InputLabel>
                                            <FormControl fullWidth >
                                                <Select
                                                    id="relationshipToTheLoanF"
                                                    label=""
                                                    onChange={e => add(e, index)}
                                                    name="relationshipToTheLoan"
                                                    value={loan?.relationshipToTheLoan}
                                                >
                                                    <MenuItem key={"PRI"} value={"PRI"}>Primary-(PRI)</MenuItem>
                                                    <MenuItem key={"SEC"} value={"SEC"}>Secondary-(SEC)</MenuItem>
                                                    <MenuItem key={"GUA"} value={"GUA"}>Guarantor-(GUA)</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid key={"purposeOfTheLoan" + index} item xs={12} sm={12} md={5} style={{ marginTop: "15px", marginRight: "15px" }}>
                                            <InputLabel

                                            >
                                                What was the purpose of the loan?
                                            </InputLabel>
                                            <FormControl fullWidth >
                                                <Select
                                                    label=""
                                                    id="purposeOfTheLoan"
                                                    onChange={e => add(e, index)}
                                                    name="purposeOfTheLoan"
                                                    value={loan.purposeOfTheLoan}
                                                >
                                                    <MenuItem key={"Real Estate"} value={"Real Estate"}>Real Estate</MenuItem>
                                                    <MenuItem key={"Micro business"} value={"Micro business"}>Micro business</MenuItem>
                                                    <MenuItem key={"Personal"} value={"Personal"}>Personal</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid key={"paymentFrequency" + index} item xs={12} sm={12} md={5} style={{ marginTop: "15px", marginRight: "15px" }}>
                                            <InputLabel

                                            >
                                                What is the agreed upon payment frequency?
                                            </InputLabel>
                                            <FormControl fullWidth >
                                                <Select
                                                    id="paymentFrequency"
                                                    label=""
                                                    onChange={e => add(e, index)}
                                                    name="paymentFrequency"
                                                    value={loan?.paymentFrequency}
                                                >
                                                    <MenuItem key={"Daily"} value={"Daily"}>Daily</MenuItem>
                                                    <MenuItem key={"Weekly"} value={"Weekly"}>Weekly</MenuItem>
                                                    <MenuItem key={"Bi-monthly"} value={"Bi-monthly"}>Bi-monthly</MenuItem>
                                                    <MenuItem key={"Monthly"} value={"Monthly"}>Monthly</MenuItem>
                                                    <MenuItem key={"Quarterly"} value={"Quarterly"}>Quarterly</MenuItem>
                                                    <MenuItem key={"Yearly"} value={" Yearly"}> Yearly</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid key={"paymentFrequencyPrice" + index} container xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                                            <InputLabel
                                                style={{ marginTop: "10px", marginBottom: "0" }}
                                            >
                                                What is the payment at the set frequency?
                                            </InputLabel>
                                            <Grid container  >
                                                <Grid item xs={12} sm={12} md={3}>
                                                    <div style={{ marginTop: "17px" }}>
                                                        <FormControl style={{ minWidth: "100%" }}>
                                                            <InputLabel id="demo-simple-select-helper">
                                                                Currency
                                                            </InputLabel>
                                                            <Select
                                                                variant="outlined"
                                                                margin="normal"
                                                                required
                                                                fullWidth
                                                                key={"demo" + index}
                                                                id={"demo" + index}
                                                                style={{ borderRight: "0px" }}
                                                                label="Currency"
                                                                onChange={e => addPrice(e, index, "paymentFrequencyPrice")}
                                                                name="type"
                                                                value={loan?.paymentFrequencyPrice?.type}
                                                            >
                                                                <MenuItem key="FCFA" default value="FCFA">
                                                                    FCFA
                                                                </MenuItem>
                                                                <MenuItem key="XAF" value="XAF">
                                                                    XAF
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={9}>
                                                    <TextField
                                                        value={String(loan?.paymentFrequencyPrice?.amount)}
                                                        onChange={e => addPrice(e, index, "paymentFrequencyPrice")}
                                                        name="amount"
                                                        style={{ borderRight: "0" }}
                                                        variant="outlined"
                                                        margin="normal"
                                                        type="number"
                                                        required
                                                        fullWidth
                                                        label="Payment Amount"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid key={"currentBalance" + index} container xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                                            <InputLabel
                                                style={{ marginTop: "10px", marginBottom: "0" }}
                                            >
                                                What is the current balance of the loan?
                                            </InputLabel>
                                            <Grid container  >
                                                {/* <Grid item xs={3} sm={3} md={3}>
                                                    <div style={{ marginTop: "17px" }}>
                                                        <FormControl style={{ minWidth: "100%" }}>
                                                            <InputLabel id="demo-simple-select-helper">
                                                                Currency
                                                            </InputLabel>
                                                            <Select
                                                                variant="outlined"
                                                                margin="normal"
                                                                required
                                                                fullWidth
                                                                id="demo-45"
                                                                label="currency"
                                                                onChange={e => addPrice(e, index, "currentBalance")}
                                                                name="type"
                                                                value={loan?.currentBalance?.type}
                                                            >
                                                                <MenuItem key="FCFA" default value="FCFA">
                                                                    FCFA{" "}
                                                                </MenuItem>
                                                                <MenuItem key="XAF" value="XAF">
                                                                    XAF{" "}
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </Grid> */}
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <TextField
                                                        value={String(loan?.currentBalance?.amount)}
                                                        onChange={e => addPrice(e, index, "currentBalance")}
                                                        name="amount"
                                                        style={{ borderRight: "0" }}
                                                        variant="outlined"
                                                        margin="normal"
                                                        type="number"
                                                        required
                                                        fullWidth
                                                        label="Current Balance"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )
                            })}
                            <Grid item xs={12} sm={12} md={10} style={{ marginTop: "20px" }}>
                                <Stack direction="row" spacing={2}>
                                    <ButtonM fullWidth className="buttonM" onClick={(e) => addLoan(e)} variant="outlined" color="success">
                                        ADD LOAN
                                    </ButtonM>
                                    <ButtonM fullWidth className="buttonM" onClick={(e) => removeLoan(e)} variant="outlined" color="error">
                                        REMOVE
                                    </ButtonM>
                                </Stack>

                            </Grid>

                            <Grid item xs={12} sm={12} md={10} >
                                <hr />
                            </Grid>
                        </>
                        }
                        <Grid item xs={12} sm={12} md={10} style={{ marginLeft: "7px", marginTop: "15px" }}>

                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={(e) => saveLiabilities(e)}

                            >
                                Update My Liabilities Information
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                <CustomBackdrop open={loader} />
            </div>}
        </>
    );
}
