import React, { useEffect, useState } from "react";
import CheckLogIn from "../../auth/CheckLogIn";
import { Container } from "reactstrap";
import { Grid } from "@material-ui/core";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PersonalInfo from "./personalInfo"
import MyAssets from "./assets"
import MyLiabilities from "./liabilities"
import SuccessSnackBar from "../snackBar/SuccessSnackBar"
import Scoreo from "./scoreo"
export default function UserProfile(props) {

    const [render, setRender] = useState(false)
    const [currentUser, setUser] = useState({})
    const [parentTab, setParentTab] = useState(1);
    const [userTab, setUserTab] = useState(0);
    const [scoreoTab, setScoreoTab] = useState(false)
    const [openS, setOpenS] = useState(false);
    const [msg, setSmsg] = useState("")


    const parentTabChanged = (event, newValue) => {
        setParentTab(newValue);
        console.log(newValue);
    };
    const userTabChanged = (event, newValue) => {
        setUserTab(newValue);
    };



    let user = localStorage.getItem("user")
    useEffect((e) => {
        let user = localStorage.getItem("user");
        setUser(JSON.parse(user))
        user = JSON.parse(user)
        setScoreoTab(user?.scoreoTabEnabled || false)
        if (user) {
            if (!user?.phone || !user?.address?.street || !user?.address?.neighborhood
                || !user?.address?.city || !user?.address?.region || !user?.address?.country
                 || !user?.birthday.month || !user?.placeOfBirth || !user?.identification?.number
                || !user?.identification?.issueDate || !user?.identification?.expireDate || !user?.idPic?.name || user?.dependents?.length <= 0) {
                setParentTab(1)
                setUserTab(0)
            }
            else if (!user?.assets?.employmentStatus || !user?.assets?.companyName || !user?.assets?.startingDate
                || !user?.assets?.jobTitle || !user?.assets?.monthlySalary?.amount
                || (user?.assets?.additionalSource == "yes" && user?.assets?.additionalMonthlySalary)) {
                setParentTab(1)
                setUserTab(1)
            }
            else if (!user?.numberOFActiveLoans) {
                setParentTab(1)
                setUserTab(2)
            }
            else {
                setScoreoTab(true)
                setParentTab(0)
            }


        }

        setRender(true)
    }, [])

    return (
        <CheckLogIn>
            {render &&
                <div style={{ paddingTop: "10px" }}>
                    <Container >
                        <SuccessSnackBar open={openS} setOpen={setOpenS} msg={msg} />
                        <Grid item xs={12}>
                            <Tabs value={parentTab} onChange={parentTabChanged} variant={"scrollable"}
                                scrollButtons={"on"} allowScrollButtonsMobile aria-label="User tab">
                                <Tab label="Scoreo" disabled={!scoreoTab} />
                                <Tab label="User Profile" />
                                <Tab label="My Referrals" />
                                <Tab label="Report Borrowers" />
                                <Tab label="FAQs" />
                                <Tab label="Credit Education" />
                            </Tabs>
                        </Grid>
                        <div style={{ marginTop: "10px" }}>
                            {parentTab == 0 &&
                                <Scoreo />
                            }
                            {parentTab == 1 &&
                                <Grid >
                                    <Tabs value={userTab} onChange={userTabChanged} variant={"scrollable"}
                                        scrollButtons={"on"} allowScrollButtonsMobile aria-label="User tab">
                                        <Tab style={{ fontSize: '12px',padding:"7px" }} label="Personal Information" />
                                        <Tab  style={{ fontSize: '12px',padding:"7px" }} label="My Assets" />
                                        <Tab style={{ fontSize: '12px',padding:"7px" }} label="My Liabilities" />
                                    </Tabs>
                                    <div style={{ marginTop: "20px" }}>
                                        {userTab == 0 &&
                                            <PersonalInfo setSmsg={setSmsg} setOpenS={setOpenS} currentUser={currentUser} setParentTab={setParentTab} setUserTab={setUserTab} />
                                        }
                                        {userTab == 1 &&
                                            <MyAssets setSmsg={setSmsg} setOpenS={setOpenS} setParentTab={setParentTab} setUserTab={setUserTab} />
                                        }
                                        {userTab == 2 &&
                                            <div>
                                                <MyLiabilities setSmsg={setSmsg} setOpenS={setOpenS} setParentTab={setParentTab} setUserTab={setUserTab} />
                                            </div>
                                        }
                                    </div>
                                </Grid>
                            }
                            {parentTab == 2 &&
                                <Grid>
                                    Referrals
                                </Grid>
                            }
                            {parentTab == 3 &&
                                <Grid>
                                    Report Borrowers
                                </Grid>
                            }
                            {parentTab == 4 &&
                                <Grid>
                                    FAQs
                                </Grid>
                            }
                            {parentTab == 5 &&
                                <Grid>
                                    Credit Education
                                </Grid>
                            }
                        </div>

                    </Container>
                </div>}
        </CheckLogIn>
    )
}