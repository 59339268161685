import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import CustomBackdrop from "../backdrop/CustomBackdrop";
import { useMediaQuery } from "react-responsive";
import CheckLoginForRegister from "../../auth/CheckLoginForRegister";
import logo from "../../assets/2020/11/cropped-MetriMeo-Blue-300x78.png";
import { useParams } from "react-router";
import userService from "../../services/UserService";

const Verifyme = (props) => {
  if(localStorage.getItem("user")){
    props.history.push("/")
  }
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 700px)" });

  const useStyles = makeStyles((theme) => ({
    root: {
      minHeight: "100vh",
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: isTabletOrMobile ? "0px" : "0px 100px 0px 100px",
    },
    icon: {
      margin: 15,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      //   padding: isTabletOrMobile ? "0px" : "0px 100px 0px 100px",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },

    buttonSignIn: {
      backgroundColor: "#3ba1da",
    },
  }));

  let { id } = useParams();
  const [loginProgress, setLoginProgress] = React.useState(false);
  const [success, setSuccess] = React.useState();
  const classes = useStyles();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (id) {
      setLoginProgress(true);
      userService
        .verifyme(id)
        .then((data) => {
          setSuccess(data.success);
          setLoginProgress(false);
        })
        .catch((err) => {
          setSuccess(err.success);
          setLoginProgress(false);
        });
    }
  }, []);

  return (
    <CheckLoginForRegister>
      <Grid container component="main" className={classes.root}>
        <CustomBackdrop open={loginProgress} setOpen={setLoginProgress} />
        <CssBaseline />
        <Grid item xs={12} sm={12} md={12} component={Paper} square>
          <div className={classes.icon}>
            <CloseIcon
              onClick={() => {
                props.history.push("/");
              }}
              style={{
                cursor:"pointer",
                float: "right",
                fontSize: "40px",
                margin: "0px",
                color: "rgba(24, 110, 184, 0.5)",
              }}
            />
          </div>
          <div className={classes.paper}>
            <a
              onClick={() => {
                props.history.push("/");
              }}
              style={{ cursor: "pointer" }}
            >
              <img src={logo} alt="" height="50px" />
            </a>
            <br />
            <div style={{ width: "100%" }}>
              <hr />
            </div>
            {success && (
              <>
                <div
                  style={{
                    fontFamily: "'Montserrat',sans-serif",
                    fontSize: "28px",
                    textAlign: "center",
                    fontWeight: "800",
                  }}
                >
                  Email Verified
                </div>
                <br />
                <p
                  style={{
                    color: "#5D7280",
                    fontSize: "15px",
                    textAlign: "center",
                  }}
                >
                  Your email address was successfully <br />
                  verified. you may now return to {" "}
                  <a
                    style={{ cursor: "pointer", color: "#E05414" }}
                    onClick={() => {
                      props.history.push("/login");
                    }}
                  >
                    {" "}
                    Log In
                  </a>
                  
                </p>
              </>
            )}
            {/* error */}
            {!success && id && (
              <>
                <div
                  style={{
                    fontFamily: "'Montserrat',sans-serif",
                    fontSize: "28px",
                    textAlign: "center",
                    fontWeight: "800",
                  }}
                >
                  Cannot Verify Email Address
                </div>
                <br />
                <p
                  style={{
                    color: "#5D7280",
                    fontSize: "15px",
                    textAlign: "center",
                  }}
                >
                  Your verification link is invalid. <br/>Please try again {" "}
                
                  
                </p>
              </>
            )}
            {/*  */}
            {!id && (
              <>
                <div
                  style={{
                    fontFamily: "'Montserrat',sans-serif",
                    fontSize: "28px",
                    textAlign: "center",
                    fontWeight: "800",
                  }}
                >
                  Please verify your email
                </div>
                <br />
                <p
                  style={{
                    color: "#5D7280",
                    fontSize: "15px",
                    textAlign: "center",
                  }}
                >
                  You're almost there! We sent an email to you just click on the
                  link <br />
                  in the email to complete your signup.
                </p>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </CheckLoginForRegister>
  );
};
export default Verifyme;
