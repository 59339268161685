export const switchLogin = () => {
  return {
    type: "switch",
  };
};
export const trueLogin = () => {
  return {
    type: "true",
  };
};
export const falseLogin = () => {
  return {
    type: "false",
  };
};
