import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import SettingsIcon from "@material-ui/icons/Settings";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import userService from "../../services/UserService";
import { useDispatch, useSelector } from "react-redux";
import { falseLogin, switchLogin } from "../../Redux/actions/LoginAction";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function CustomAccountList(props) {
  const [dialog, setDia] = React.useState(false);

  const handleClickOpen = () => {
    setDia(true);
  };

  const handleClose = () => {
    setDia(false);
  };

  const dispatch = useDispatch();

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    userService.deleteUser(userService.getloggedinuser()._id).then(data=>{
    setDia(false);

      setTimeout(()=>{
        userService.logout();
        dispatch(switchLogin());
      },500)
      console.log(data);
    }).catch(err=>{
      // console.log(err);
    })
    setOpen(!open);
  };

  return (
    <>
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
        ></ListSubheader>
      }
      className={classes.root}
    >
      <ListItem
      style={{paddingLeft:"12px"}}
        button
        onClick={() => {
          props.setPageState(0);
        }}
      >
        <ListItemIcon style={{minWidth:"33px" }}>
          <AccountCircleIcon style={{minWidth:"33px"}}/>
        </ListItemIcon>
        <ListItemText primary="Account" />
      </ListItem>
      <ListItem
      style={{paddingLeft:"12px",paddingRight:"0px"}}
        button
        onClick={() => {
          props.setPageState(1);
        }}
      >
        <ListItemIcon style={{minWidth:"33px"}}>
          <SettingsIcon style={{minWidth:"33px"}} />
        </ListItemIcon>
        <ListItemText  primary="Change Password" />
      </ListItem>
    
      <ListItem button style={{paddingLeft:"12px"}} onClick={handleClickOpen} >
        <ListItemIcon style={{minWidth:"33px"}}>
          <DeleteForeverIcon style={{minWidth:"33px"}} />
        </ListItemIcon>
        <ListItemText primary="Delete Account" />
      </ListItem>
    </List>


    <Dialog
        open={dialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <b>Warning</b> User will be permanently deleted and cannot be recovered, Are you Sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleClick} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
