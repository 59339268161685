import React, { useEffect, useState, useRef } from "react";
import "./scoreo.css";
import { Container } from "reactstrap";
import { Grid } from "@material-ui/core";
import SemiCircleProgressBar from "./circle/semi";
import userService from "../../services/UserService";
import SnackBar from "../snackBar/SnackBar";
import { useMediaQuery } from "react-responsive";



export default function Scoreo() {
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 700px)" });

    const [open, setOpen] = React.useState(false);
    const [msg, setmsg] = React.useState("");

    const [render, setRender] = useState(false);
    const [score, setScore] = useState({})
    const [oldScore, setOldScore] = useState({})
    const [nextDay, setNextDay] = useState()
    const [percent,setPercent]=useState()
    function join(d) {
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        return `${mo} ${da}, ${ye}`;
    }
    function dayDiff(scoreDate) {
        console.log("====> > > >", scoreDate);
        scoreDate = new Date(scoreDate);
        let fortnightAway = new Date()
        fortnightAway = new Date(fortnightAway.setDate(scoreDate.getDate() + 14));
        console.log({ scoreDate, current: new Date() });
        const diffTime = Math.abs((new Date()) - scoreDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        console.log(diffDays);
        return (15-diffDays)
    }

    const rangeInBetween=(value)=>{
        const old_min=350, old_max=850,new_max=100,new_min=0
        const new_value = ( (value - old_min) / (old_max - old_min) ) * (new_max - new_min) + new_min
        console.log("====>",new_value);
        return new_value
    }

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"))
        userService.getScoreo(user._id).then(function (res) {
            if (res.success) {
                let latest, old = "";
                if (res.data?.length > 1) {
                    latest = res.data?.[1]
                    old = res.data?.[0]
                }
                else
                    latest = res.data?.[0]

                if (latest) {
                    const date = latest.date;
                    latest.date = join(new Date(date));
                    setPercent(rangeInBetween(latest.score))
                    setScore(latest)
                    setNextDay(dayDiff(date))
                }
                if (old) {
                    const date = old.date;
                    old.date = join(new Date(date));
                    setOldScore(old)
                }
            }
            else {
                setOpen(true);
                setmsg("Error")
            }
            setRender(true)
        })
            .catch(function (error) {
                setRender(true)
                setOpen(true);
                setmsg("Catch Error")
            });
    }, [])
    return (
        <> {render &&
            <Container style={{ marginBottom: "50px", marginTop: "30px", paddingLeft: isTabletOrMobile && "0px" }}>
                <SnackBar open={open} setOpen={setOpen} msg={msg} />
                <Grid item xs={12} sm={12} md={12} style={{ flexDirection: isTabletOrMobile ? "column" : "row", display: "flex", alignItems: "center" }}>
                    <Grid item xs={12} sm={12} md={3} >
                        <div className="circle" style={{ margin: "auto" }}>
                            {oldScore?.score ? <>
                                <div style={{ fontSize: "28px", marginTop: "20px" }}>
                                    {oldScore?.score}
                                </div>
                                <div style={{ fontSize: "14px" }}>
                                    {oldScore?.date}
                                </div>
                            </>
                                : <div style={{ marginTop: "40px" }}>-</div>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} style={{ textAlign: "Center", marginTop: isTabletOrMobile ? "30px" : "0px" }}>
                        <SemiCircleProgressBar diameter={isTabletOrMobile ? 340 : 500} updatedOn={"Updated " + score.date} customText={(score.score)} percentage={percent} strokeWidth={isTabletOrMobile ? 15 : 20} />
                        <div style={{ fontSize: isTabletOrMobile ? "18px" : "24px", marginTop: "10px", textAlign: "center" }}>
                            Next available update in {nextDay} days

                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} style={{ direction: "rtl", marginTop: isTabletOrMobile ? "30px" : "0px" }}>
                        <div className="circle" style={{ margin: "auto" }}>
                            {oldScore?.score != null ?
                                <>
                                    <div style={{ fontSize: "28px", marginTop: "20px", direction: "ltr" }}>
                                        {((score.score)) - (oldScore.score) <= 0 ? (score.score) - (oldScore.score) : "+" + (String((score.score) - (oldScore.score)))}
                                    </div>
                                    <div style={{ fontSize: "14px" }}>
                                        Change
                                    </div>
                                </>
                                : <div style={{ marginTop: "40px" }}>-</div>
                            }
                        </div>
                    </Grid>
                </Grid>

                {/* <Grid item xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
                    <div style={{ fontSize: "24px", textAlign: "center" }}>
                        Next available update in {nextDay} days

                    </div>
                </Grid> */}
            </Container>}
        </>
    )
}