import React, { useEffect, useState, useRef } from "react";

import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";

const SemiCircleProgress = ({
  strokeWidth = 10,
  background = "#D0D0CE",
  diameter = 200,
  orientation = "up",
  direction = "right",
  showPercentValue = false,
  customText,
  percentage,
  updatedOn
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 700px)" });

  const [stroke,setStroke]=useState("")
  const [remarks,setRemarks]=useState("")
  useEffect((e)=>{
    if(customText){
      if(customText<580){
        setStroke("#e50000")
        setRemarks("Poor")
      }
      else if(customText<=669){
        setStroke("#FFBF00");
        setRemarks("Fair")
      }
      else if(customText<=739){
        setStroke("#FFFF00");
        setRemarks("Good")
      }
      else if(customText<=799){
        setStroke("#90ee90");
        setRemarks("Very Good")
      }
      else if(customText>=799){
        setStroke("#02B732");
        setRemarks("Exceptional")
      }

    }
  },[])
  
  const coordinateForCircle = diameter / 2;
  const radius = (diameter - 2 * strokeWidth) / 2;
  const circumference = Math.PI * radius;
  console.log({coordinateForCircle ,radius,circumference });
  let percentageValue;
  if (percentage > 100) {
    percentageValue = 100;
  } else if (percentage < 0) {
    percentageValue = 0;
  } else {
    percentageValue = percentage;
  }
  const semiCirclePercentage = percentageValue * (circumference / 100);

  let rotation;
  if (orientation === "down") {
    if (direction === "left") {
      rotation = "rotate(180deg) rotateY(180deg)";
    } else {
      rotation = "rotate(180deg)";
    }
  } else {
    if (direction === "right") {
      rotation = "rotateY(180deg)";
    }
  }

  return (
    <div className="semicircle-container" style={{ position: "relative" }}>
        {customText && (
          <span
          className="semicircle-percent-value"
          style={{
            marginLeft: isTabletOrMobile ? "41%" :"38%",
            top:"28%",
            fontSize: isTabletOrMobile ?"30px" :"45px",
            fontWeight:"600",
            textAlign: "center",
            position: "absolute"
          }}
        >
          {customText}
        </span>
      )}
      {remarks && (
          <span
          className="semicircle-percent-value"
          style={{
            marginLeft: isTabletOrMobile ? remarks.length>7 ? "35%" :"42%": remarks.length>7 ? "34%" :"39%",
            top:"58%",
            fontSize:isTabletOrMobile ?"19px" : "27px",
            textAlign: "center",
            position: "absolute"
          }}
        >
          {remarks}
        </span>
      )}
      {updatedOn && (
          <span
          className="semicircle-percent-value"
          style={{
            marginLeft: "28%",
            top:"80%",
            fontSize: isTabletOrMobile? "15px" : "20px",
            textAlign: "center",
            position: "absolute"
          }}
        >
          {updatedOn}
        </span>
      )}
      <svg
        width={diameter}
        height={diameter / 2}
        style={{ transform: rotation, overflow: "hidden" }}
      >
        <circle
          cx={coordinateForCircle}
          cy={coordinateForCircle}
          r={radius}
          fill="none"
          stroke={background}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          style={{
            strokeDashoffset: circumference
          }}
        />
        <circle
          cx={coordinateForCircle}
          cy={coordinateForCircle}
          r={radius}
          fill="none"
          stroke={stroke}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          style={{
            strokeDashoffset: semiCirclePercentage,
            transition:
              "stroke-dashoffset .3s ease 0s, stroke-dasharray .3s ease 0s, stroke .3s"
          }}
        />
      </svg>
      <span
          className="semicircle-percent-value"
          style={{
            left:isTabletOrMobile ? "-4%": "-2%",
            fontSize:  isTabletOrMobile && "10px",
            bottom:"-1%",
            fontWeight:"600",
            position: "absolute"
          }}
        >
            350
        </span>
        <span
          className="semicircle-percent-value"
          style={{
            bottom:"-3px",
            fontSize:  isTabletOrMobile && "10px",
            fontWeight:"600",
            left:isTabletOrMobile && "102%",
            position: "absolute"
          }}
        >
            850
        </span>
      {showPercentValue && (
        <span
          className="semicircle-percent-value"
          style={{
            width: "100%",
            left: "0",
            textAlign: "center",
            bottom: orientation === "down" ? "auto" : "0",
            position: "absolute"
          }}
        >
          {percentage}%
        </span>
      )}
      
    </div>
  );
};

SemiCircleProgress.propTypes = {
  strokeWidth: PropTypes.number,
  background: PropTypes.string,
  diameter: PropTypes.number,
  orientation: PropTypes.oneOf(["up", "down"]),
  direction: PropTypes.oneOf(["left", "right"]),
  showPercentValue: PropTypes.bool,
};

export default SemiCircleProgress;