import React, { useEffect, useState, useRef } from "react";
import Button from "@material-ui/core/Button";
import { Container } from "reactstrap";
import { Grid } from "@material-ui/core";
import CountryNames from "../register/CountryNames";
import MenuItem from "@mui/material/MenuItem";
import userService from "../../services/UserService";
import Select from "@mui/material/Select";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import InputLabel from '@mui/material/InputLabel';
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import CustomBackdrop from "../backdrop/CustomBackdrop";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SnackBar from "../snackBar/SnackBar";


// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

export default function PersonalInfo(props) {
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 700px)" });
    const useStyles = makeStyles((theme) => ({
        root: {
            minHeight: "100vh",
        },
        image: {
            backgroundImage: "url(./wp-content/uploads/2020/12/newlogin.jpeg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
        },
        paper: {
            margin: theme.spacing(8, 4),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: isTabletOrMobile ? "0px" : "0px 100px 0px 100px",
        },
        icon: {
            display: "flex",
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        form: {
            width: "100%", // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
            backgroundColor: "#3ba1da",
        },
        buttonSignIn: {
            backgroundColor: "#3ba1da",
        },
    }));
    const [currentUser, setUser] = useState({})
    const classes = useStyles();
    const fileChooser = useRef(null);
    const [render, setRender] = useState(false)
    const [loader, setLoader] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [msg, setmsg] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [today, setToday] = useState()

    const setCountry = (country) => {
        setUser({ ...currentUser, address: { ...currentUser.address, country } })
        console.log(country);
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"))
        let todayD = new Date();
        setToday(todayD.getFullYear() + "-" + (todayD.getMonth() + 1) + "-" + (todayD.getDate() <= 9 ? "0" + todayD.getDate() : todayD.getDate()))
        setUser(user)
        setRender(true)
    }, [])

    const SavePersonalInfo = (e) => {
        e.preventDefault();
        console.log(currentUser);
        if (currentUser?.type == "business") {
            if (!currentUser?.fname || currentUser?.fname.length < 3) {
                setmsg("Business name should be at least 3 characters");
                setOpen(true);
            }
        }
        else if (currentUser?.type == "individual") {
            if (!currentUser?.fname || currentUser?.fname.length < 3) {
                setmsg("First name should be at least 3 characters");
                setOpen(true);
            }
            else if (!currentUser?.lname || currentUser?.lname.length < 3) {
                setmsg("Last name should be at least 3 characters");
                setOpen(true);
            }
        }
        else if (!currentUser?.phone || currentUser?.phone.length < 6) {
            setmsg("Primary number should be at least 6 numbers");
            setOpen(true);
        }
        if (!currentUser?.address?.street || currentUser?.address?.street?.length < 3) {
            setmsg("Street Address should be at least 3 characters");
            setOpen(true);
        }
        else if (!currentUser?.address?.neighborhood || currentUser?.address?.neighborhood?.length < 3) {
            setmsg("Neighborhood should be at least 3 characters");
            setOpen(true);
        }
        else if (!currentUser?.address?.city || currentUser?.address?.city?.length < 3) {
            setmsg("City should be at least 3 characters");
            setOpen(true);
        }
        else if (!currentUser?.address?.region || currentUser?.address?.region?.length < 3) {
            setmsg("Region should be at least 3 characters");
            setOpen(true);
        }
        else if (!currentUser?.address?.country || currentUser?.address?.country?.length < 3) {
            setmsg("Country should be at least 3 characters");
            setOpen(true);
        }
        // else if (!currentUser?.mailingAddress || currentUser?.mailingAddress?.length < 3) {
        //     setmsg("Mailing Address should be at least 3 characters");
        //     setOpen(true);
        // }
        else if (!currentUser?.birthday?.month || !currentUser?.birthday?.day || !currentUser?.birthday?.year) {
            setmsg("Full Date of birth is required");
            setOpen(true);
        }
        else if (!currentUser?.placeOfBirth || currentUser?.placeOfBirth?.length < 3) {
            setmsg("Palce of birth should be at least 3 characters");
            setOpen(true);
        }
        else if (!currentUser?.identification?.number || currentUser?.identification?.number?.length < 3) {
            setmsg(currentUser?.identification.type + " number should be at least 3 characters");
            setOpen(true);
        }

        else if (!currentUser?.identification.issueDate) {
            setmsg("Issuance date is required");
            setOpen(true);
        }
        else if (new Date(currentUser?.identification?.issueDate).getTime() >= new Date().getTime()) {
            setmsg("Issuance date cannot be in the future");
            setOpen(true);
        }
        else if (!currentUser?.identification?.expireDate) {
            setmsg("Expiration date is required");
            setOpen(true);
        }
        else if (new Date(currentUser?.identification?.issueDate).getTime() == new Date(currentUser?.identification?.expireDate).getTime()) {
            setmsg("Expiration date cannot be the same as issuance date");
            setOpen(true);
        }
        else if (new Date(currentUser?.identification?.issueDate).getTime() > new Date(currentUser?.identification?.expireDate).getTime()) {
            setmsg("Expiration date cannot be before the issuance date");
            setOpen(true);
        }
        else if (!currentUser?.idPic?.name) {
            setmsg("Picture of ID is required");
            setOpen(true);
        }
        else if ((!currentUser?.dependents  && currentUser?.dependents!=0) || currentUser?.dependents<0) {
            setmsg("Number Of dependents are required");
            setOpen(true);
        }
        else {
            setLoader(true);
            userService.updateUser(currentUser).then(function (res) {
                if (res.success) {
                    setLoader(false);

                    props.setSmsg("Personal Information Saved");
                    props.setOpenS(true)
                    console.log("here==>");
                    let updatedUser = JSON.stringify(res.user)
                    localStorage.setItem("user", updatedUser);
                    console.log(res.user);
                    setUser(res.user)
                    props.setUserTab(1)
                }
                else {
                    setLoader(false);

                    setmsg("Something went wrong");
                    setOpen(true);
                }
            })
                .catch(function (error) {
                    setLoader(false);
                    setmsg("Something went wrong");
                    console.log(error);
                    setOpen(true);
                });
        }
    }

    const imageUpload = (e) => {
        e.preventDefault();
        setLoading(true)
        const image = e.target.files[0];
        const imageForm = new FormData();
        imageForm.append("image", image);
        // const data = new URLSearchParams(imageForm)
        fetch(process.env.REACT_APP_host + "/image", {
            method: "POST",
            body: imageForm,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    setUser({ ...currentUser, idPic: { url: data.data.url, name: e.target.files[0]?.name } })
                }
                setLoading(false)

            }).catch((error) => {
                setLoading(false)
                console.error(error);
            });


    }

    const allowNumber = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]

    return (
        <>
            {render &&
                <div style={{ marginBottom: "50px" }}>
                    <SnackBar open={open} setOpen={setOpen} msg={msg} />

                    <form className={classes.form} onSubmit={SavePersonalInfo} style={{ display: "flex" }}>
                        <Grid container>
                            {currentUser.type != "business" && <><Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    value={currentUser.fname}
                                    onChange={(e) => { setUser({ ...currentUser, fname: e.target.value }) }}
                                    required
                                    fullWidth
                                    label={"First Name"}
                                    name="fname"
                                />
                            </Grid>
                                <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        value={currentUser?.lname}
                                        onChange={(e) => { setUser({ ...currentUser, lname: e.target.value }) }}
                                        required
                                        fullWidth
                                        label={"Last Name"}
                                        name="lname"
                                    />
                                </Grid> </>}
                            {currentUser.type == "business" && <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    value={currentUser.fname}
                                    onChange={(e) => { setUser({ ...currentUser, fname: e.target.value }) }}
                                    required
                                    fullWidth
                                    label={"Business Name"}
                                    name="fname"
                                />
                            </Grid>
                            }
                            <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    onKeyDown={(evt) => (!allowNumber.includes(evt.key) && evt.keyCode != 8) && evt.preventDefault()}
                                    value={currentUser.phone}
                                    onChange={(e) => { setUser({ ...currentUser, phone: e.target.value }) }}
                                    required
                                    fullWidth
                                    type="number"
                                    label={"Primary Phone Number"}
                                    name="PrimaryPhoneNumber"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    value={currentUser.secPhone}
                                    onChange={(e) => { setUser({ ...currentUser, secPhone: e.target.value }) }}
                                    fullWidth
                                    onKeyDown={(evt) => (!allowNumber.includes(evt.key) && evt.keyCode != 8) && evt.preventDefault()}
                                    type="number"
                                    label={"Secondary Phone Number"}
                                    name="lname"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} style={{ marginRight: "15px" }}>
                                <div
                                    style={{
                                        fontFamily: "'Open Sans',sans-serif",
                                        fontSize: "17px",
                                        fontWeight: "600",
                                        marginTop: "10px",
                                    }}
                                >
                                    Physical Address *
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                                <TextField
                                    onChange={(e) => { setUser({ ...currentUser, address: { ...currentUser?.address, street: e.target.value } }) }}
                                    value={currentUser?.address?.street}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Street Address"
                                    name="streetAddress"
                                    key="streetAddress"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                                {/* apartment */}
                                <TextField
                                    onChange={(e) => { setUser({ ...currentUser, address: { ...currentUser?.address, apartment: e.target.value } }) }}
                                    value={currentUser?.address?.apartment}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label="Apartment Number"
                                    name="Apartment"
                                />
                            </Grid>
                            {/* neighborhood */}
                            <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                                <TextField
                                    onChange={(e) => { setUser({ ...currentUser, address: { ...currentUser?.address, neighborhood: e.target.value } }) }}
                                    value={currentUser?.address?.neighborhood}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Neighborhood"
                                    name="neighborhood"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                                {/* city */}
                                <TextField
                                    onChange={(e) => { setUser({ ...currentUser, address: { ...currentUser?.address, city: e.target.value } }) }}
                                    value={currentUser?.address?.city}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="City"
                                    name="city"
                                />
                            </Grid>
                            {/* region */}
                            <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                                <TextField
                                    onChange={(e) => { setUser({ ...currentUser, address: { ...currentUser.address, region: e.target.value } }) }}
                                    value={currentUser?.address?.region}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Region"
                                    name="region"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                                <CountryNames country={currentUser?.address?.country} setCountry={setCountry} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                                <TextField
                                    value={currentUser.postalCode}
                                    onChange={(e) => { setUser({ ...currentUser, postalCode: e.target.value }) }}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label="Postal Code"
                                    name="postalCode"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                                <TextField
                                    value={currentUser.mailingAddress}
                                    onChange={(e) => { setUser({ ...currentUser, mailingAddress: e.target.value }) }}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label="Mailing Address"
                                    name="mailingAddress"
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} style={{ marginRight: "15px" }}>
                                <div
                                    style={{
                                        fontFamily: "'Open Sans',sans-serif",
                                        fontSize: "17px",
                                        fontWeight: "600",
                                        marginTop: "10px",
                                        // textAlign: "center",
                                    }}
                                >
                                    Date Of Birth *
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                                <div style={{ marginTop: "15px" }}>
                                    <FormControl style={{ minWidth: 145, marginRight: "15px" }}>
                                        {/* {!birthday.month &&  */}
                                        <InputLabel
                                            id="demo-simple-select-helper-label"
                                        >
                                            Month
                                        </InputLabel>
                                        {/* } */}
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            label="Month"
                                            value={currentUser?.birthday?.month}
                                            onChange={(e) => setUser({ ...currentUser, birthday: { ...currentUser.birthday, month: e.target.value } })}>


                                            <MenuItem key={"Jan"} value={"Jan"}>Jan</MenuItem>
                                            <MenuItem key={"Feb"} value={"Feb"}>Feb</MenuItem>
                                            <MenuItem key={"Mar"} value={"Mar"}>Mar</MenuItem>
                                            <MenuItem key={"Apr"} value={"Apr"}>Apr</MenuItem>
                                            <MenuItem key={"May"} value={"May"}>May</MenuItem>
                                            <MenuItem key={"Jun"} value={"Jun"}>Jun</MenuItem>
                                            <MenuItem key={"Jul"} value={"Jul"}>Jul</MenuItem>
                                            <MenuItem key={"Aug"} value={"Aug"}>Aug</MenuItem>
                                            <MenuItem key={"Sep"} value={"Sep"}>Sep</MenuItem>
                                            <MenuItem key={"Oct"} value={"Oct"}>Oct</MenuItem>
                                            <MenuItem key={"Nov"} value={"Nov"}>Nov</MenuItem>
                                            <MenuItem key={"Dec"} value={"Dec"}>Dec</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl style={{ minWidth: 145, marginRight: "15px" }}>
                                        {/* {!birthday.day &&  */}
                                        <InputLabel
                                            id="demo-simple-select-helper-label"
                                        >
                                            Day
                                        </InputLabel>
                                        {/* // } */}
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            label="day"
                                            value={currentUser?.birthday?.day}
                                            onChange={(e) => setUser({ ...currentUser, birthday: { ...currentUser.birthday, day: e.target.value } })}>

                                            <MenuItem value=""></MenuItem>
                                            {Array.from(Array(31).keys()).map((number) => (
                                                <MenuItem key={number + 1} value={number + 1}>{number + 1}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl style={{ minWidth: 140, marginTop: isTabletOrMobile && "8px" }}>
                                        {/* {!birthday.year &&  */}
                                        <InputLabel
                                            id="demo-simple-select-helper-label"
                                        >
                                            Year
                                        </InputLabel>
                                        {/* } */}
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            label="year"
                                            value={currentUser?.birthday?.year}
                                            onChange={(e) => setUser({ ...currentUser, birthday: { ...currentUser.birthday, year: e.target.value } })}>
                                            <MenuItem value=""></MenuItem>
                                            {Array.from(Array(122).keys()).map((number) => (
                                                <MenuItem key={number + 1} value={number + 1900}>{number + 1900}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                                <TextField
                                    value={currentUser?.placeOfBirth}
                                    onChange={(e) => {
                                        setUser({ ...currentUser, placeOfBirth: e.target.value });
                                    }}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Place Of Birth"
                                    name="birthCity"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} style={{ marginRight: "15px" }}>
                                <div
                                    style={{
                                        fontFamily: "'Open Sans',sans-serif",
                                        fontSize: "17px",
                                        fontWeight: "600",
                                        marginTop: "10px",
                                        // textAlign: "center",
                                    }}
                                >
                                    Identification Document (ID)*
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                                <FormControl component="fieldset" style={{ marginTop: "15px" }}>
                                    <RadioGroup
                                        value={currentUser?.identification?.type}
                                        onChange={e => setUser({ ...currentUser, identification: { ...currentUser.identification, type: e.target.value } })}
                                        row
                                        aria-label="idCard"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel
                                            value="Identity Card"
                                            control={<Radio />}
                                            label="Identity Card"
                                        />
                                        <FormControlLabel
                                            value="Passport"
                                            control={<Radio />}
                                            label="Passport"
                                        />
                                        <FormControlLabel
                                            value="Birth Certificate"
                                            control={<Radio />}
                                            label="Birth Certificate"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                                <TextField
                                    value={currentUser?.identification?.number}
                                    onChange={e => setUser({ ...currentUser, identification: { ...currentUser.identification, number: e.target.value } })}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label={currentUser.identification.type + " Number"}
                                    name="idn"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px", marginTop: "5px" }}>
                                <InputLabel style={{ fontSize: "13px" }} id="relationshipToTheLoan">
                                    Issuance Date
                                </InputLabel>
                                <input
                                    className="date"
                                    min="1900-01-01"
                                    value={currentUser?.identification?.issueDate}
                                    onChange={e => setUser({ ...currentUser, identification: { ...currentUser.identification, issueDate: e.target.value } })}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="IssuanceDate"
                                    onKeyDown={(event) => {
                                        event.preventDefault();
                                    }}
                                    label="Issuance Date"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    type="date"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px", marginTop: "5px" }}>
                                <InputLabel style={{ fontSize: "13px" }} id="relationshipToTheLoan">
                                    Expiration Date
                                </InputLabel>
                                <input
                                    className="date"
                                    min="1900-01-01"
                                    value={currentUser?.identification?.expireDate}
                                    onChange={e => setUser({ ...currentUser, identification: { ...currentUser.identification, expireDate: e.target.value } })}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    onKeyDown={(event) => {
                                        event.preventDefault();
                                    }}
                                    name="ExpirationDate"
                                    label="Expiration Date"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    type="date"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={8} style={{ paddingRight: !isTabletOrMobile && "13px" }}>
                                        <TextField
                                            value={currentUser?.idPic?.name}
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            onClick={e => fileChooser.click}
                                            disabled
                                            name="filename"
                                            style={{ marginRight: "10px" }}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={12} md={4}>
                                        <Button
                                            variant="contained"
                                            component="label"
                                            fullWidth
                                            ref={fileChooser}
                                            style={{ borderRadius: "10px", marginTop: "16px", height: "50px" }}
                                        >

                                            Choose file ...
                                            <input
                                                onChange={imageUpload}
                                                type="file"
                                                hidden
                                                accept="image/*"
                                            />
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} style={{ marginRight: "15px" }}>
                                    <div
                                        style={{
                                            fontFamily: "'Open Sans',sans-serif",
                                            fontSize: "12px",
                                            marginTop: "10px",
                                            // textAlign: "center",
                                        }}
                                    >
                                        Current attachment: <a target="_blank" href={currentUser?.idPic?.url}>{currentUser?.idPic?.name}</a>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                                <TextField
                                    value={currentUser?.dependents}
                                    onChange={e => {
                                        setUser({ ...currentUser, dependents: parseInt(e.target.value) })
                                    }}
                                    onKeyDown={(evt) => (!allowNumber.includes(evt.key) && evt.keyCode != 8) && evt.preventDefault()}
                                    type="number"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="dependents"
                                    label="How many dependents do you have?"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={10} style={{ marginLeft: "7px", marginTop: "15px" }}>

                                <Button
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={(e) => SavePersonalInfo(e)}

                                >
                                    Update My Personal Information
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                    <CustomBackdrop open={loader} />
                    <CustomBackdrop open={loading} setOpen={setLoading} />

                </div>}
        </>
    );
}
