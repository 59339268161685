import React, { useEffect, useState, useRef } from "react";
import Button from "@material-ui/core/Button";
import { Container } from "reactstrap";
import { Grid } from "@material-ui/core";
import CountryNames from "../register/CountryNames";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import InputLabel from '@mui/material/InputLabel';
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SnackBar from "../snackBar/SnackBar";
import CustomBackdrop from "../backdrop/CustomBackdrop";
import userService from "../../services/UserService";

export default function MyAssets(props) {
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 700px)" });
    const useStyles = makeStyles((theme) => ({
        root: {
            minHeight: "100vh",
        },
        image: {
            backgroundImage: "url(./wp-content/uploads/2020/12/newlogin.jpeg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
        },
        paper: {
            margin: theme.spacing(8, 4),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: isTabletOrMobile ? "0px" : "0px 100px 0px 100px",
        },
        icon: {
            display: "flex",
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        form: {
            width: "100%", // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
            backgroundColor: "#3ba1da",
        },
        buttonSignIn: {
            backgroundColor: "#3ba1da",
        },
    }));
    const classes = useStyles();
    const categoryDB = ["Notebook", "Desktop PC", "Monitor"];
    const [render, setRender] = useState(false)
    const [loader, setLoader] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [msg, setmsg] = React.useState("");
    const [currentUser, setUser] = useState({})
    const [assets, setAssets] = useState({ employmentStatus: "Employed", companyName: "", startingDate: "", employmentEndtDate: "", jobTitle: "", additionalSource: "", monthlySalary: { currency: "", amount: "" }, additionalMonthlySalary: { currency: "", amount: "" } })
    // const [today, setToday] = useState()

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"))
        console.log(user);
        let todayD = new Date();
        let month = todayD.getMonth() + 1 <= 9 ? "0" + todayD.getMonth() + 1 : todayD.getMonth() + 1
        todayD = todayD.getFullYear() + "-" + (month) + "-" + (todayD.getDate() <= 9 ? "0" + todayD.getDate() : todayD.getDate())
        // setToday(todayD)
        if (user?.assets) {
            setAssets({
                ...user.assets, employmentStatus: user?.assets?.employmentStatus ?? "Employed",
                // startingDate: user?.assets?.startingDate ? user?.assets?.startingDate : todayD,
                // employmentEndtDate: user?.assets?.employmentEndtDate ? user?.assets?.employmentEndtDate : todayD
            })
        }
        setUser(user)
        setRender(true)
    }, [])

    const saveAssets = (e) => {
        e.preventDefault();
        if (!assets.employmentStatus) {
            setmsg("Please select employment status");
            setOpen(true);
        }
        else if (assets.employmentStatus !="Unemployed" && (!assets?.companyName || assets?.companyName.length < 3)) {
            setmsg("Employer/Company name should be at least 3 characters");
            setOpen(true);
        }
        else if (!assets?.startingDate) {
            setmsg("Employment start date is required");
            setOpen(true);
        }

        else if (new Date(assets?.startingDate).getTime() >= new Date().getTime()) {
            setmsg("Employment start date cannot be in the future");
            setOpen(true);
        }
        // else if (!currentUser?.identification?.expireDate) {
        //     setmsg("Expiration date is required");
        //     setOpen(true);
        // }
        else if (new Date(assets?.startingDate).getTime() == new Date(assets?.employmentEndtDate).getTime()) {
            setmsg("Employment start date cannot be the same as Employment end date");
            setOpen(true);
        }
        else if (new Date(assets?.startingDate).getTime() > new Date(assets?.employmentEndtDate).getTime()) {
            setmsg("Employment start date cannot be before the Employment end date");
            setOpen(true);
        }

        else if (!assets?.jobTitle || assets?.jobTitle.length < 3) {
            setmsg("Job title should be at least 3 characters");
            setOpen(true);
        }
        else if (!assets?.monthlySalary?.amount || assets?.monthlySalary?.amount <= 0 || assets?.monthlySalary?.amount.length <= 0) {
            setmsg("Monthly salary is required");
            setOpen(true);
        }
        else if (assets?.additionalSource == "Yes" && ((!assets?.additionalMonthlySalary?.amount || assets?.additionalMonthlySalary?.amount <= 0 || assets?.additionalMonthlySalary?.amount.length <= 0))) {

            setmsg("Additional source monthly average salary is required");
            setOpen(true);

        }
        else {
            setLoader(true);
            console.log({ _id: currentUser._id, assets });
            userService.updateUser({ _id: currentUser._id, assets }).then(function (res) {
                if (res.success) {
                    console.log("here==>");
                    let updatedUser = JSON.stringify(res.user)
                    localStorage.setItem("user", updatedUser);
                    console.log(res.user);
                    setUser(res.user)
                    props.setSmsg("Assets Information Saved");
                    props.setOpenS(true)
                    props.setUserTab(2)
                }
                else {
                    setmsg("Something went wrong");
                    setOpen(true);
                }
                setLoader(false);
            })
                .catch(function (error) {
                    setLoader(false);
                    setmsg("Something went wrong");
                    console.log(error);
                    setOpen(true);
                });
        }
    }

    return (
        <> {render &&
            <div style={{ marginBottom: "50px" }}>
                <SnackBar open={open} setOpen={setOpen} msg={msg} />
                <form className={classes.form} style={{ display: "flex" }} onSubmit={saveAssets}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                            <div style={{ marginTop: "15px" }}>
                                <FormControl fullWidth style={{ marginRight: "15px" }}>
                                    {/* {!assets?.employmentStatus && */}
                                    <InputLabel id="employed">Employment Status</InputLabel>
                                    {/* // } */}
                                    <Select
                                        labelId="employed"
                                        id="employment-status"
                                        value={assets?.employmentStatus}
                                        label="Employment Status"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => setAssets({ ...assets, employmentStatus: e.target.value })}
                                    >

                                        <MenuItem value={"Employed"}>Employed</MenuItem>
                                        <MenuItem value={"Self-Employed"}>Self-Employed</MenuItem>
                                        <MenuItem value={"Unemployed"}>Unemployed</MenuItem>
                                        <MenuItem value={"Retired"}>Retired</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                            <TextField
                                value={assets.companyName}
                                onChange={(e) => {
                                    setAssets({ ...assets, companyName: e.target.value });
                                }}
                                variant="outlined"
                                margin="normal"
                                required={assets.employmentStatus!="Unemployed"}
                                fullWidth
                                label="Most Recent Employer/Company Name"
                                name="EmployerCompanyName "
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px", marginTop: "5px" }}>
                            <InputLabel style={{fontSize:"13px"}} id="relationshipToTheLoan">
                                Employment Start Date
                            </InputLabel>
                            <input
                                min="1900-01-01"
                                variant="outlined"
                                margin="normal"
                                className="date"
                                required
                                fullWidth
                                name="EmploymentStartDate "
                                label="Employment Start Date"
                                type="date"
                                value={assets?.startingDate}
                                onChange={(e) => setAssets({ ...assets, startingDate: e.target.value })}
                                onKeyDown={(event) => {
                                    event.preventDefault();
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px", marginTop: "5px" }}>
                            <InputLabel style={{fontSize:"13px"}} id="relationshipToTheLoan">
                                Employment End Date
                            </InputLabel>
                            <input
                            min="1900-01-01"
                                variant="outlined"
                                margin="normal"
                                className="date"
                                required
                                fullWidth
                                name="EmploymentEndtDate"
                                label="Employment End Date"
                                InputLabelProps={{ shrink: true, required: true }}
                                type="date"
                                value={assets?.employmentEndtDate}
                                onChange={(e) => setAssets({ ...assets, employmentEndtDate: e.target.value })}
                                onKeyDown={(event) => {
                                    event.preventDefault();
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                            <TextField
                                value={assets?.jobTitle}
                                onChange={(e) => setAssets({ ...assets, jobTitle: e.target.value })}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="Most Recent Job Title"
                                name="JobTitle"
                            />
                        </Grid>
                        <Grid container xs={12} sm={12} md={5} style={{ marginRight: "15px", marginTop: "16px" }}>

                            <FormControl style={{ minWidth: "100%" }}>
                                <InputLabel id="demo-simple-select-helper">
                                    Currency
                                </InputLabel>
                                <Select
                                    fullWidth
                                    id="demo-simple-select-helper"
                                    label="Currency"
                                    value={assets?.monthlySalary?.currency}
                                    onChange={(e) => setAssets({ ...assets, monthlySalary: { ...assets.monthlySalary, currency: e.target.value } })}
                                >
                                    <MenuItem key="dollar" default value="FCFA">
                                        FCFA{" "}
                                    </MenuItem>
                                    <MenuItem key="dollar" value="XAF">
                                        XAF{" "}
                                    </MenuItem>
                                </Select>
                            </FormControl>

                        </Grid>

                        <Grid container xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>


                            <TextField
                                value={String(assets?.monthlySalary?.amount).toString()}
                                onChange={(e) => {
                                    setAssets({ ...assets, monthlySalary: { ...assets.monthlySalary, amount: parseFloat(e.target.value) } })
                                    if (e.target.value < 0)
                                        setAssets({ ...assets, monthlySalary: { ...assets.monthlySalary, amount: 0 } })

                                }
                                }
                                style={{ borderRight: "0" }}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                variant="outlined"
                                margin="normal"
                                type="number"
                                required
                                fullWidth
                                label="Most Recent Monthly Salary"
                                name="monthlysalary "
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} style={{ marginRight: "15px" }}>
                            <div
                                style={{
                                    fontFamily: "'Open Sans',sans-serif",
                                    fontSize: "17px",
                                    fontWeight: "600",
                                    marginTop: "10px",
                                    // textAlign: "center",
                                }}
                            >
                                Additional Income Sources *
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                            <FormControl component="fieldset" style={{ marginTop: "15px" }}>
                                <RadioGroup
                                    value={assets?.additionalSource}
                                    onChange={(e) => setAssets({ ...assets, additionalSource: e.target.value })}

                                    row
                                    aria-label="idCard"
                                    name="row-radio-buttons-group"
                                >
                                    <FormControlLabel
                                        value="Yes"
                                        control={<Radio />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="No"
                                        control={<Radio />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {assets?.additionalSource == "Yes" &&
                            <>
                                <Grid container xs={12} sm={12} md={5} style={{ marginRight: "15px" }}>
                                    {/* <Grid item xs={3} sm={3} md={3}>
                                        <div style={{ marginTop: "16px" }}>
                                            <FormControl style={{ minWidth: "100%" }}>
                                                <InputLabel id="demo-simple-select-helper">
                                                    Currency
                                                </InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    label="Currency"
                                                    value={assets?.additionalMonthlySalary?.currency}
                                                    onChange={(e) => setAssets({ ...assets, additionalMonthlySalary: { ...assets.additionalMonthlySalary, currency: e.target.value } })}
                                                >
                                                    <MenuItem key="dollar" default value="FCFA">
                                                        FCFA{" "}
                                                    </MenuItem>
                                                    <MenuItem key="dollar" value="XAF">
                                                        XAF{" "}
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid> */}
                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextField
                                            value={String(assets?.additionalMonthlySalary?.amount).toString()}
                                            onChange={(e) => {
                                                setAssets({ ...assets, additionalMonthlySalary: { ...assets.additionalMonthlySalary, amount: parseFloat(e.target.value) } })
                                                if (e.target.value < 0)
                                                    setAssets({ ...assets, additionalMonthlySalary: { ...assets.additionalMonthlySalary, amount: 0 } })

                                            }
                                            }
                                            style={{ borderRight: "0" }}
                                            variant="outlined"
                                            margin="normal"
                                            type="number"
                                            required
                                            fullWidth
                                            label="Monthly Average "
                                            name="monthlyaverage"
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        }
                        <Grid item xs={12} sm={12} md={10} style={{ marginLeft: "7px", marginTop: "15px" }}>

                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={(e) => saveAssets(e)}

                            >
                                Update My Assets Information
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                <CustomBackdrop open={loader} />
            </div>}
        </>
    );
}
